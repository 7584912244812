// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import HomePage from './pages/HomePage';
import LuxuryEcoTourismPage from './pages/LuxuryEcoTourismPage';
import LuxuryRealEstatePage from './pages/LuxuryRealEstatePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import BuzzFlockPage from './pages/BuzzFlockPage';
import SchemaMarkup from './components/SchemaMarkup';
import { useScrollToTop } from './hooks/useScrollToTop';
import HNWIWorldPage from './pages/HNWIWorldPage';

const tagManagerArgs = { gtmId: 'GTM-K66Z2NL3' };

function ScrollToTop() {
  useScrollToTop();
  return null;
}

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#0D0D0D" />
        <link rel="manifest" href="/manifest.json" />
        
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '240509290360991');
            fbq('init', '1537859096845732');
            fbq('trackSingle', '240509290360991', 'PageView');
            fbq('trackSingle', '1537859096845732', 'PageView');
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=240509290360991&ev=PageView&noscript=1"
            />
            <img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=1537859096845732&ev=PageView&noscript=1"
            />
          `}
        </noscript>
      </Helmet>

      <SchemaMarkup />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/success" element={<HomePage />} />
        <Route path="/luxury-eco-tourism-strategy" element={<LuxuryEcoTourismPage />} />
        <Route path="/luxury-eco-tourism-strategy/success" element={<LuxuryEcoTourismPage />} />
        <Route path="/luxury-real-estate-strategy" element={<LuxuryRealEstatePage />} />
        <Route path="/luxury-real-estate-strategy/success" element={<LuxuryRealEstatePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/privacy-policy/success" element={<PrivacyPolicyPage />} />
        <Route path="/buzzflock" element={<BuzzFlockPage />} />
        <Route path="/buzzflock/success" element={<BuzzFlockPage />} />
        <Route path="/hnwi-world" element={<HNWIWorldPage />} />
        <Route path="/hnwi-world/success" element={<HNWIWorldPage />} />
      </Routes>
    </Router>
  );
}

export default App;