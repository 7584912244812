// src/data/hnwiArticles.js



export const hnwiArticles = [
    {
      id: 1,
      title: "The Global Shift: Why the World's Wealthiest Are Betting on India",
      subtitle: "Insights and trends shaping the choices of the world's wealthiest.",
      category: "Market Insights",
      readTime: "4 min read",
      date: "2024-11-27",
      summary: "India's luxury real estate sector continues its meteoric rise, standing out as a preferred destination for HNWIs and UHNWIs. Recent growth is driven by a unique combination of strategic infrastructure projects, eco-conscious developments, and culturally immersive opportunities. This positions India as a strong alternative to global hubs like Dubai and Singapore.",
      image: "/images/hnwi/global-wealth-a1.webp",
      platform: "linkedin",
      externalLink: "https://www.linkedin.com/pulse/global-interest-why-worlds-wealthiest-n5yqc/",
      author: "HNWI Chronicles"
    },
    
  ];