// src/components/HeroSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { ChevronRight } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';
import './HeroSection.css';

const HeroSection = ({ title = "", subtitle, ctaText, ctaLink }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <section className="relative min-h-screen flex items-center justify-center overflow-hidden hero-section">
      {/* Background */}
      <div
        className="absolute inset-0 hero-background"
        style={{
          backgroundColor: colors.background,
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-background-dark opacity-50" />

      <div className="container mx-auto px-4 py-32 relative z-10">
        <motion.div
          className="max-w-5xl mx-auto text-center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Overline */}
          <motion.div
            variants={itemVariants}
            className="inline-flex items-center space-x-2 mb-6 justify-center"
          >
            <span className="h-px w-8 bg-accent" />
            <span
              className="text-sm uppercase tracking-wider font-bold"
              style={{
                fontFamily: fonts.primary,
                color: colors.accent,
              }}
            >
              Innovate Today, Inspire Tomorrow
            </span>
            <span className="h-px w-8 bg-accent" />
          </motion.div>

          {/* Title */}
          <motion.h1
            variants={itemVariants}
            className="text-5xl md:text-7xl font-bold mb-8"
            style={{
              fontFamily: fonts.heading,
              color: colors.primary,
            }}
          >
            {title.split(' ').map((word, index) => (
              <span
                key={index}
                className={index % 2 === 0 ? "text-accent" : ""}
              >
                {word}{' '}
              </span>
            ))}
          </motion.h1>

          {/* Subtitle */}
          <motion.p
            variants={itemVariants}
            className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto"
            style={{
              color: colors.lightText,
              fontFamily: fonts.primary,
            }}
          >
            {subtitle}
          </motion.p>

          {/* CTA Button */}
          <motion.div
            variants={itemVariants}
            className="flex justify-center"
          >
            <ScrollLink
              to={ctaLink}
              smooth={true}
              duration={500}
              className="group inline-flex items-center px-8 py-4 rounded-full text-lg font-semibold transition-all duration-300"
              style={{
                backgroundColor: colors.accent,
                color: colors.buttonText,
                fontFamily: fonts.primary,
              }}
            >
              <span>{ctaText}</span>
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </ScrollLink>
          </motion.div>
        </motion.div>
      </div>

      {/* Scroll Indicator */}
      <motion.div
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        animate={{
          y: [-5, 5, -5],
        }}
        transition={{
          repeat: Infinity,
          duration: 2,
          ease: "easeInOut",
        }}
      >
        <div
          className="w-6 h-10 rounded-full p-2 flex items-start justify-center"
          style={{ border: `2px solid ${colors.accent}` }}
        >
          <div
            className="w-1 h-3 rounded-full"
            style={{ backgroundColor: colors.accent }}
          />
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;