// src/components/Newsletter.js

import React from 'react';
import colors from '../colors';
import fonts from '../fonts';

const Newsletter = () => (
  <section 
    id="newsletter" 
    className="py-20 relative overflow-hidden"
    style={{
      background: `linear-gradient(135deg, 
        ${colors.accent} 0%,
        #E5A500 25%,
        #FFC529 50%,
        ${colors.accentLight} 75%,
        ${colors.accent} 100%
      )`,
      backgroundSize: '200% 200%',
      animation: 'gradientShift 15s ease infinite'
    }}
  >
    {/* Animated overlay */}
    <div 
      className="absolute inset-0 opacity-20"
      style={{
        background: `radial-gradient(circle at 50% 50%, 
          rgba(255, 255, 255, 0.8) 0%, 
          rgba(255, 255, 255, 0) 70%
        )`
      }}
    />

    <div className="container mx-auto px-4 md:px-8 text-center relative z-10">
      <h2
        className="text-4xl md:text-5xl font-bold mb-8"
        style={{ 
          fontFamily: fonts.heading,
          color: colors.background,
          textShadow: '0 1px 2px rgba(0,0,0,0.1)'
        }}
      >
        Subscribe to Our Newsletter
      </h2>

      <p 
        className="text-xl md:text-2xl mb-8"
        style={{ 
          color: colors.background,
          fontWeight: 500
        }}
      >
        Get weekly business architecture and 'Disruptive' Discussions
        delivered to your inbox.
      </p>

      <a
        href="https://montaigne.beehiiv.com/subscribe"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-8 py-4 rounded-full text-lg font-semibold shadow-md hover:shadow-lg transition duration-300 hover:scale-105 transform"
        style={{ 
          fontFamily: fonts.primary,
          background: colors.background,
          color: colors.accent
        }}
      >
        Subscribe Now
      </a>
    </div>
  </section>
);

export default Newsletter;