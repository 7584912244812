// src/colors.js
const colors = {
  primary: '#FFFFFF',
  secondary: '#1A1A1A',
  accent: '#FFB800',      // Warmer golden yellow
  accentLight: '#FFD700', // Brighter gold for hover
  accentDark: '#CC9900',  // Darker gold for depth
  lightText: '#E5E5E5',   
  background: '#0D0D0D',  
  buttonText: '#000000',  // Black text on gold buttons
  header: '#0D0D0D',
  success: '#22C55E',
  error: '#EF4444',
  warning: '#F59E0B'
};

export default colors;