// src/pages/HNWIWorldPage.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Newsletter from '../components/Newsletter';
import ContactForm from '../components/ContactForm';
import colors from '../colors';
import fonts from '../fonts';
import { Clock, ExternalLink, ChevronLeft, ChevronRight } from 'lucide-react';
import { hnwiArticles } from '../data/hnwiArticles';

const Section = React.memo(({ children, className, id, bgColor = 'bg-background' }) => (
  <section id={id} className={`py-20 ${className} ${bgColor}`}>
    <div className="container mx-auto px-4 md:px-8">{children}</div>
  </section>
));

const ContactSection = () => (
  <Section id="contact">
    <h2
      className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary"
      style={{ fontFamily: fonts.heading }}
    >
      Transform <span style={{ color: colors.accent }}>Your Business</span> Let's Talk
    </h2>
    <div className="max-w-lg mx-auto">
      <div className="bg-secondary p-8 rounded-lg shadow-lg">
        <ContactForm />
      </div>
    </div>
  </Section>
);

const ArticlesSection = ({ paginatedArticles, currentPage, totalPages, handlePageChange }) => (
  <Section id="articles" className="py-20">
    <h2
      className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary"
      style={{ fontFamily: fonts.heading }}
    >
      Latest <span className="text-accent">Insights</span>
    </h2>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 max-w-7xl mx-auto">
      {paginatedArticles.map((article, index) => (
        <motion.article
          key={article.id}
          className="flex flex-col bg-secondary rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          <div className="relative">
            <img
              src={article.image}
              alt={article.title}
              className="w-full h-48 object-cover transform transition-transform duration-300 hover:scale-105"
              loading="lazy"
            />
            <div className="absolute top-4 left-4">
              <span
                className="px-3 py-1 rounded-full text-xs font-medium"
                style={{ backgroundColor: colors.accent, color: colors.buttonText }}
              >
                {article.category}
              </span>
            </div>
          </div>

          <div className="p-6 flex flex-col flex-grow">
            <div className="flex items-center mb-3" style={{ color: colors.lightText }}>
              <Clock size={14} className="mr-1" />
              <span className="text-xs">{article.readTime}</span>
              <span className="mx-2">•</span>
              <span className="text-xs">{article.author}</span>
            </div>

            <h3
              className="text-xl font-bold mb-3"
              style={{ color: colors.primary }}
            >
              {article.title}
            </h3>

            <p
              className="text-sm mb-4 flex-grow"
              style={{ color: colors.lightText }}
            >
              {article.summary}
            </p>

            <div className="flex items-center justify-between mt-4">
              <span className="text-xs" style={{ color: colors.lightText }}>
                {article.date}
              </span>
              <a
                href={article.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-accent hover:text-accentLight transition-all duration-300"
              >
                <span className="text-sm font-medium">Read More</span>
                <ExternalLink size={16} className="ml-2" />
              </a>
            </div>
          </div>
        </motion.article>
      ))}
    </div>

    {totalPages > 1 && (
      <div className="mt-12 flex justify-center items-center space-x-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 rounded-full hover:bg-secondary transition-colors duration-300 disabled:opacity-50"
          aria-label="Previous Page"
        >
          <ChevronLeft size={24} className="text-primary" />
        </button>

        <div className="flex space-x-2">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors duration-300 
                  ${currentPage === i + 1 ? 'bg-accent text-black' : 'text-primary hover:bg-secondary'}`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 rounded-full hover:bg-secondary transition-colors duration-300 disabled:opacity-50"
          aria-label="Next Page"
        >
          <ChevronRight size={24} className="text-primary" />
        </button>
      </div>
    )}
  </Section>
);

const HNWIWorldPage = () => {
  const ARTICLES_PER_PAGE = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(hnwiArticles.length / ARTICLES_PER_PAGE);
  const paginatedArticles = hnwiArticles.slice(
    (currentPage - 1) * ARTICLES_PER_PAGE,
    currentPage * ARTICLES_PER_PAGE
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>HNWI World - Insights for High Net Worth Individuals | Montaigne</title>
        <meta
          name="description"
          content="Exclusive insights and analysis for High Net Worth Individuals. Stay ahead with Montaigne's expert perspectives on luxury markets, wealth management, and investment opportunities."
        />
        <link rel="canonical" href="https://www.montaigne.co/hnwi-world" />
      </Helmet>

      <div className="bg-background min-h-screen">
        <Header />

        <main>
          <HeroSection
            title="Exclusive Insights for Elite Investors"
            subtitle="Navigate the world of wealth with expert perspectives on luxury markets, investment strategies, and wealth management."
            ctaText="Explore Insights"
            ctaLink="articles"
          />

          <ArticlesSection
            paginatedArticles={paginatedArticles}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />

          {/* Updated Newsletter Section */}
          <Newsletter />

          <ContactSection />
        </main>

        <Footer />
      </div>
    </>
  );
};

export default HNWIWorldPage;