// src/components/CreatorForm.js

import React, { useState } from 'react';
import colors from '../colors';
import fonts from '../fonts';

function CreatorForm() {
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+91',
    phoneNumber: '',
    niche: '',
    portfolioLink: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const response = await fetch('https://formspree.io/f/xjkvbynp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          ...formData,
          phoneNumber: `${formData.countryCode} ${formData.phoneNumber}`
        })
      });

      if (response.ok) {
        setStatus('success');
        setFormData({
          name: '',
          email: '',
          countryCode: '+91',
          phoneNumber: '',
          niche: '',
          portfolioLink: ''
        });
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Name */}
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>
      {/* Email */}
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>
      {/* Phone Number */}
      <div>
        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Phone Number
        </label>
        <div className="flex">
          <input
            type="text"
            id="countryCode"
            name="countryCode"
            value={formData.countryCode}
            onChange={handleChange}
            className="form-control w-1/4 mr-2"
            placeholder="+91"
            required
          />
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="form-control w-3/4"
            placeholder="Enter your phone number"
            required
          />
        </div>
      </div>
      {/* Niche */}
      <div>
        <label
          htmlFor="niche"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Niche/Expertise
        </label>
        <input
          type="text"
          id="niche"
          name="niche"
          value={formData.niche}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>
      {/* Portfolio Link */}
      <div>
        <label
          htmlFor="portfolioLink"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Portfolio Link
        </label>
        <input
          type="url"
          id="portfolioLink"
          name="portfolioLink"
          value={formData.portfolioLink}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>
      {/* Submit Button */}
      <div>
        <button
          type="submit"
          className="btn-animate w-full text-buttonText px-4 py-2 rounded-md hover:bg-accent-light transition duration-300 font-semibold"
          style={{ fontFamily: fonts.primary, background: colors.accent }}
          disabled={status === 'sending'}
        >
          {status === 'sending' ? 'Submitting...' : 'Submit'}
        </button>
      </div>
      {/* Feedback Messages */}
      {status === 'success' && (
        <p className="mt-4 text-green-400">Thank you for your submission. We'll get back to you soon!</p>
      )}
      {status === 'error' && (
        <p className="mt-4 text-red-400">Oops! There was an error. Please try again.</p>
      )}
    </form>
  );
}

export default CreatorForm;