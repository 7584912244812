// src/utils/pixelTracker.js
export const trackPixelEvent = (eventName, params = {}) => {
    // Check if fbq exists in the window object
    if (typeof window !== 'undefined' && window.fbq) {
      // Track on primary pixel
      window.fbq('trackSingle', '240509290360991', eventName, params);
      // Track on secondary pixel
      window.fbq('trackSingle', '1537859096845732', eventName, params);
    } else {
      console.warn('Facebook Pixel not loaded');
    }
  };
  