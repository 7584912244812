// src/pages/BuzzFlockPage.js

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import colors from '../colors';
import fonts from '../fonts';
import ProductionTeamSection from '../components/ProductionTeamSection';
import CreatorForm from '../components/CreatorForm';
import ContactForm from '../components/ContactForm';
import { Briefcase, Users, Rocket, Star, Globe, Heart } from 'lucide-react';
import buzzFlockLogo from '../images/buzzflock/buzz_logo.png';

const BuzzFlockPage = () => {
  const [showCreatorForm, setShowCreatorForm] = useState(false);

  const handleJoinCommunity = () => {
    setShowCreatorForm(true);
  };

  useEffect(() => {
    if (showCreatorForm) {
      setTimeout(() => {
        const joinElement = document.getElementById('join');
        if (joinElement) {
          joinElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [showCreatorForm]);

  const pageTitle = "BuzzFlock - Amplify Your Brand with User-Generated Content | Montaigne";
  const pageDescription = "BuzzFlock by Montaigne revolutionizes your product's market presence with strategy-driven user-generated content. Leverage authentic stories to elevate your brand.";
  const ogImageUrl = "https://www.pexels.com/photo/a-woman-recording-herself-with-a-smartphone-7676409/"; // Make sure this image exists
  const canonicalUrl = "https://www.montaigne.co/buzzflock";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={ogImageUrl} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
        <meta property="twitter:image" content={ogImageUrl} />

        <link rel="canonical" href={canonicalUrl} />
        </Helmet>
      <div className="bg-background text-text min-h-screen">
        <Header />
        <main>
          <HeroSection
            title="BuzzFlock: Empowering Brands through Authentic Stories"
            subtitle="Leverage the power of user-generated content to elevate your brand and connect with your audience on a deeper level."
            ctaText="Partner with Us"
            ctaLink="contact"
          />
          
          {/* BuzzFlock Logo Section */}
          <section className="py-6 bg-secondary"> {/* Reduced padding */}
            <div className="container mx-auto px-4 md:px-8 flex justify-center items-center">
              <img 
                src={buzzFlockLogo} 
                alt="BuzzFlock Logo" 
                className="w-auto h-36 md:h-48"
                style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
              />
            </div>
          </section>

          <AboutBuzzFlockSection />
          <UserGeneratedContentSection />
          <WhyBuzzFlockSection />
          <BuzzFlockForwardSection />
          <StatisticsSection />
          <ProductionTeamSection />
          <CallToActionSection onJoinCommunity={handleJoinCommunity} />
          <ContactUsSection />
          {showCreatorForm && <JoinCommunitySection />}
        </main>
        <Footer />
      </div>
    </>
  );
};

// Adjusted AboutBuzzFlockSection
const AboutBuzzFlockSection = () => (
  <section id="about" className="py-6 bg-secondary"> {/* Reduced top padding */}
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-8 text-center"
        style={{ fontFamily: fonts.heading, color: colors.primary }}
      >
        What is <span style={{ color: colors.accent }}>BuzzFlock</span>?
      </h2>
      <p
        className="text-xl md:text-2xl mb-12 text-center max-w-4xl mx-auto"
        style={{ color: colors.lightText }}
      >
        BuzzFlock is a pioneering initiative by Montaigne and Hashira that
        harnesses the power of user-generated content (UGC) to propel brands to
        new heights. We strategically curate authentic content created by a
        community of over <strong>200 creators</strong>, amplifying your
        brand's reach and resonance.
      </p>
    </div>
  </section>
);

const UserGeneratedContentSection = () => (
  <section id="ugc" className="py-20 bg-background">
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-8 text-center"
        style={{ fontFamily: fonts.heading, color: colors.primary }}
      >
        The Power of <span style={{ color: colors.accent }}>User-Generated Content</span>
      </h2>
      <p
        className="text-xl md:text-2xl mb-12 text-center max-w-4xl mx-auto"
        style={{ color: colors.lightText }}
      >
        In today's digital landscape, authentic content reigns supreme. User-generated content not only builds trust but also creates emotional connections with your audience. BuzzFlock leverages this by transforming real user experiences into compelling narratives that drive engagement and conversion.
      </p>
    </div>
  </section>
);

const WhyBuzzFlockSection = () => {
    const features = [
      {
        icon: Rocket,
        title: "Strategic Storytelling",
        description: "Our expertise ensures your brand's story is told in a way that resonates deeply with your target audience."
      },
      {
        icon: Heart,
        title: "Emotional Engagement",
        description: "We create content that not only informs but also evokes emotions, fostering brand loyalty."
      },
      {
        icon: Globe,
        title: "Growing Creator Network",
        description: "Access a diverse and expanding community of creators from various niches and demographics."
      },
      {
        icon: Star,
        title: "Commitment to Excellence",
        description: "We are dedicated to delivering exceptional results through innovative strategies."
      },
      {
        icon: Briefcase,
        title: "Tailored Solutions",
        description: "We customize strategies to align with your brand's unique goals and market position."
      },
      {
        icon: Users,
        title: "HNI & UHNI Focus",
        description: "Specializing in high-net-worth segments, we connect you with audiences that matter most."
      },
    ];

  return (
    <section id="why-buzzflock" className="py-20 bg-secondary">
      <div className="container mx-auto px-4 md:px-8">
        <h2
          className="text-4xl md:text-5xl font-bold mb-8 text-center"
          style={{ fontFamily: fonts.heading, color: colors.primary }}
        >
          Why Choose <span style={{ color: colors.accent }}>BuzzFlock</span>?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-background p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
              <feature.icon className="w-12 h-12 text-accent mb-4" />
              <h3
                className="text-2xl font-semibold mb-2"
                style={{ fontFamily: fonts.heading, color: colors.primary }}
              >
                {feature.title}
              </h3>
              <p style={{ color: colors.lightText }}>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const BuzzFlockForwardSection = () => (
  <section id="forward" className="py-20 bg-background">
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-8 text-center"
        style={{ fontFamily: fonts.heading, color: colors.primary }}
      >
        BuzzFlock <span style={{ color: colors.accent }}>Forward</span>
      </h2>
      <p
        className="text-xl md:text-2xl mb-12 text-center max-w-4xl mx-auto"
        style={{ color: colors.lightText }}
      >
        As we move forward, BuzzFlock is committed to innovating and expanding our capabilities. With upcoming AI-driven analytics and a growing community, we're poised to set new benchmarks in user-generated content strategies.
      </p>
    </div>
  </section>
);

const StatisticsSection = () => (
  <section id="statistics" className="py-20 bg-secondary">
    <div className="container mx-auto px-4 md:px-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
        <div>
          <h3
            className="text-5xl font-bold"
            style={{ fontFamily: fonts.heading, color: colors.accent }}
          >
            200+
          </h3>
          <p className="text-xl mt-2" style={{ color: colors.lightText }}>Content Creators</p>
        </div>
        <div>
          <h3
            className="text-5xl font-bold"
            style={{ fontFamily: fonts.heading, color: colors.accent }}
          >
            1000+
          </h3>
          <p className="text-xl mt-2" style={{ color: colors.lightText }}>Videos Produced</p>
        </div>
        <div>
          <h3
            className="text-5xl font-bold"
            style={{ fontFamily: fonts.heading, color: colors.accent }}
          >
            100M+
          </h3>
          <p className="text-xl mt-2" style={{ color: colors.lightText }}>Audience Reached</p>
        </div>
      </div>
    </div>
  </section>
);

const CallToActionSection = ({ onJoinCommunity }) => (
  <section 
    id="cta" 
    className="py-20 relative overflow-hidden"
    style={{
      background: `linear-gradient(135deg, 
        ${colors.accent} 0%,
        #E5A500 25%,
        #FFC529 50%,
        ${colors.accentLight} 75%,
        ${colors.accent} 100%
      )`,
      backgroundSize: '200% 200%',
      animation: 'gradientShift 15s ease infinite'
    }}
  >
    {/* Animated overlay */}
    <div 
      className="absolute inset-0 opacity-20"
      style={{
        background: `radial-gradient(circle at 50% 50%, 
          rgba(255, 255, 255, 0.8) 0%, 
          rgba(255, 255, 255, 0) 70%
        )`
      }}
    />

    <div className="container mx-auto px-4 md:px-8 text-center relative z-10">
      <h2
        className="text-4xl md:text-5xl font-bold mb-8"
        style={{ 
          fontFamily: fonts.heading,
          color: colors.background,
          textShadow: '0 1px 2px rgba(0,0,0,0.1)'
        }}
      >
        Join the BuzzFlock Community
      </h2>

      <p 
        className="text-xl md:text-2xl mb-8"
        style={{ 
          color: colors.background,
          fontWeight: 500
        }}
      >
        Become a part of a vibrant community of creators and collaborate with top-tier brands.
      </p>

      <button
        onClick={onJoinCommunity}
        className="px-8 py-4 rounded-full text-lg font-semibold shadow-md hover:shadow-lg transition duration-300 hover:scale-105 transform"
        style={{ 
          fontFamily: fonts.primary,
          background: colors.background,
          color: colors.accent
        }}
      >
        Join Community
      </button>
    </div>
  </section>
);
  
  const ContactUsSection = () => (
    <section id="contact" className="py-20 bg-background">
      <div className="container mx-auto px-4 md:px-8">
        <h2
          className="text-4xl md:text-5xl font-bold mb-12 text-center"
          style={{ fontFamily: fonts.heading }}
        >
          Transform <span style={{ color: colors.accent }}>Your Business</span> Let's Talk
        </h2>
        <div className="max-w-lg mx-auto">
          <div className="bg-secondary p-8 rounded-lg shadow-lg">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
  
  const JoinCommunitySection = () => (
    <section id="join" className="py-20 bg-background">
      <div className="container mx-auto px-4 md:px-8">
        <div className="bg-secondary p-8 rounded-lg shadow-lg">
          <h3
            className="text-3xl font-semibold mb-4 text-center"
            style={{ fontFamily: fonts.heading, color: colors.primary }}
          >
            Join the BuzzFlock Community
          </h3>
          <p style={{ color: colors.lightText }} className="mb-6 text-center">
            Partner with BuzzFlock and join the revolution of user-generated content.
          </p>
          <CreatorForm />
        </div>
      </div>
    </section>
  );
  
  export default BuzzFlockPage;
