// src/pages/HomePage.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import MilestonesSection from '../components/MilestonesSection';
import ScrollSection from '../components/ScrollSection';
import AboutSection from '../components/AboutSection';
import JourneyVideoSection from '../components/JourneyVideoSection';
import ServicesSection from '../components/ServicesSection';
import IndustriesSection from '../components/IndustriesSection';
import TestimonialsSection from '../components/TestimonialsSection';
import Newsletter from '../components/Newsletter';
import ContactForm from '../components/ContactForm';
import colors from '../colors';
import fonts from '../fonts';

const HomePage = () => {
  const achievements = [
    { title: "Encyclopedia of Aluminium & Its Alloys", image: "/images/achievements/encyclopedia.jpg" },
    { title: "India's Fastest Growing Luxury Second Home", image: "/images/achievements/luxofy.png" },
    { title: "India's Largest Aqua Farmer Network", image: "/images/achievements/ryrj.png" },
    { title: "Telangana's Largest Public Voting App", image: "/images/achievements/dmc.png" },
    { title: "North India's Largest Real Estate Aggregator", image: "/images/achievements/ix.png" },
  ];

  const clients = [
    { logo: '/images/clients/matrix.png' },
    { logo: '/images/clients/1acre.png' },
    { logo: '/images/clients/uc.png' },
    { logo: '/images/clients/luxofy.png' },
    { logo: '/images/clients/ix.png' },
    { logo: '/images/clients/mu.png' },
    { logo: '/images/clients/dmc.png' },
  ];

  return (
    <>
      <Helmet>
        <title>Montaigne - Innovate Today, Inspire Tomorrow</title>
        <meta
          name="description"
          content="Montaigne Smart Business Solutions blends scalable business architecture with acute business strategies to solve complex challenges and shape the future."
        />
        <link rel="canonical" href="https://www.montaigne.co" />
      </Helmet>
      <div className="bg-background text-text min-h-screen">
        <Header />
        <main>
          <HeroSection
            title="Business Architecture, Go-To-Market Strategies"
            subtitle="We partner with organizations to transform possibilities into distinguished realities through strategy, thoughtful innovation, and measured execution."
            ctaText="Discover Our Approach"
            ctaLink="services"
          />

          {/* Journey Video Section */}
          <section style={{ backgroundColor: colors.background }}>
            <JourneyVideoSection />
          </section>

          {/* Milestones Section */}
          <section style={{ backgroundColor: colors.secondary }}>
            <MilestonesSection items={achievements} title="Our" subtitle="Milestones" />
          </section>

          {/* About Section */}
          <section style={{ backgroundColor: colors.background }}>
            <AboutSection />
          </section>

          {/* Services Section */}
          <section style={{ backgroundColor: colors.secondary }}>
            <ServicesSection />
          </section>

          {/* Industries Section */}
          <section style={{ backgroundColor: colors.accentDark }}>
            <IndustriesSection />
          </section>

          {/* Partners Section */}
          <section style={{ backgroundColor: colors.secondary }}>
            <ScrollSection items={clients} title="Our" subtitle="Partners" showTitles={false} />
          </section>

          {/* Testimonials Section */}
          <section style={{ backgroundColor: colors.background }}>
            <TestimonialsSection />
          </section>

          {/* Newsletter Section */}
          <Newsletter />

          {/* Contact Section */}
          <ContactSection />
        </main>
        <Footer />
      </div>
    </>
  );
};

const ContactSection = () => (
  <section
    id="contact"
    className="py-20"
    style={{ backgroundColor: colors.background }}
  >
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-12 text-center"
        style={{ color: colors.primary, fontFamily: fonts.heading }}
      >
        Transform <span style={{ color: colors.accent }}>Your Business</span> Let's Talk
      </h2>
      <div className="max-w-lg mx-auto">
        <div className="bg-secondary p-8 rounded-lg shadow-lg">
          <ContactForm />
        </div>
      </div>
    </div>
  </section>
);

export default HomePage;
