// src/components/ContactForm.js

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { trackPixelEvent } from '../utils/pixelTracker';
import colors from '../colors';
import fonts from '../fonts';
import './ContactForm.css';

function ContactForm() {
  const [status, setStatus] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    companyEmail: '',
    countryCode: '+1',
    phoneNumber: '',
    problemStatement: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const response = await fetch('https://formspree.io/f/xldrknzp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          ...formData,
          phoneNumber: `${formData.countryCode} ${formData.phoneNumber}`
        })
      });

      if (response.ok) {
        setStatus('success');
        const currentPath = location.pathname;
        const successPath = currentPath === '/' ? '/success' : `${currentPath}/success`;
        
        // Update URL without page refresh
        navigate(successPath, { replace: true });
        
        trackPixelEvent('SubmitForm', {
          formType: 'contact',
          source: currentPath
        });

        setFormData({
          name: '',
          companyEmail: '',
          countryCode: '+1',
          phoneNumber: '',
          problemStatement: ''
        });

        setTimeout(() => {
          navigate(currentPath, { replace: true });
        }, 500);
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div>
        <label
          htmlFor="companyEmail"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Company Email
        </label>
        <input
          type="email"
          id="companyEmail"
          name="companyEmail"
          value={formData.companyEmail}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div>
        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Phone Number
        </label>
        <div className="phone-input-container">
          <input
            type="text"
            id="countryCode"
            name="countryCode"
            value={formData.countryCode}
            onChange={handleChange}
            className="country-code-input"
            placeholder="+1"
            required
          />
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="phone-number-input"
            placeholder="Enter your phone number"
            required
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="problemStatement"
          className="block text-sm font-medium mb-1"
          style={{ color: colors.text }}
        >
          Current Problem Statement or Vision to be Discussed
        </label>
        <textarea
          id="problemStatement"
          name="problemStatement"
          value={formData.problemStatement}
          onChange={handleChange}
          rows="4"
          className="form-control"
          required
        ></textarea>
      </div>

      <div>
        <button
          type="submit"
          className="btn-animate w-full text-buttonText px-4 py-2 rounded-md hover:bg-accent-light transition duration-300 font-semibold"
          style={{ fontFamily: fonts.primary, background: colors.accent }}
          disabled={status === 'sending'}
        >
          {status === 'sending' ? 'Sending...' : 'Send Message'}
        </button>
      </div>

      {status === 'success' && (
        <p className="mt-4 text-green-400">Thank you for your message. We'll get back to you soon!</p>
      )}
      {status === 'error' && (
        <p className="mt-4 text-red-400">Oops! There was an error. Please try again.</p>
      )}
    </form>
  );
}

export default ContactForm;