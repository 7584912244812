// src/components/SchemaMarkup.js

import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaMarkup = () => {
  const companySchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Montaigne Smart Business Solutions",
    "url": "https://www.montaigne.co",
    "logo": "https://www.montaigne.co/logo.png",
    "description": "Montaigne Smart Business Solutions is a go to market strategy and business architecture firms that aims to innovate today and inspire tomorrow.",
    "founder": {
      "@type": "Person",
      "name": "Rohith Sampathi"
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Hyderabad",
      "addressCountry": "India"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+919700500900",
      "contactType": "customer service"
    }
  };

  const marketUnwindedSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Market Unwinded",
    "description": "An AI-powered Knowledge Base and Personal Strategy Assistant for business architecture and go to market strategy.",
    "brand": {
      "@type": "Brand",
      "name": "Montaigne"
    },
    "offers": {
      "@type": "Offer",
      "price": "Custom",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": "25"
    }
  };

  const buzzFlockSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "BuzzFlock",
    "description": "A user-generated content strategy service that amplifies brand presence through authentic stories.",
    "brand": {
      "@type": "Brand",
      "name": "Montaigne"
    },
    "offers": {
      "@type": "Offer",
      "price": "Custom",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": "135"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(companySchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(marketUnwindedSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(buzzFlockSchema)}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;